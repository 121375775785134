import styles from './index.module.scss'
import {useDispatch, useSelector} from "react-redux";
import {showPopup} from "/redux/action-creaters/popup";
import {popupTypes} from "/redux/reducers/popupReducer";
import {useEffect, useMemo, useState} from "react";
import {declOfNum} from "/utils";
import {fetchDomains} from "/redux/action-creaters/location";
import Link from 'next/link'
import Menu from "../menu";
import Search from "/components/layouts/main/search"
import Catalog from "/components/layouts/main/catalog"
import Button from "/components/common/button"
import Cart from "/components/layouts/main/cart"
import User from "/components/layouts/main/user/"
import Icon from "/components/common/icon";
import React from "react";
import clsx from "clsx";
import {setShowMobileMenu} from "/redux/action-creaters/pageState";
import {getDeliveryCityData, setLocation} from "/redux/action-creaters/location";
import Cookies from 'js-cookie'
import {useRouter} from "next/router";
import {Api} from "../../../../api";
import Wp from "../../../common/feedBack/wp";
import Tg from "../../../common/feedBack/tg";
import {setLocationLogic} from "../../../../utils/location";
import Loader from "../../../common/loader";

export default React.memo(function Header({contactsData, user, basket, isScrolled}) {
    const dispatch = useDispatch();
    const {cityName, deliveyCityData, deliveyCityDataLoaded, domains} = useSelector(state => state.location)
    const {isShowMobileMenu} = useSelector(state => state.pageState)
    const [searchActivate, setSearchActive] = useState(0);
    const [detectedCity, setDetectedCity] = useState(null);
    const [doSetDetectedCity, setDoSetDetectedCity] = useState(false);
    const {
        edostMinDays,
    } = useSelector(state => state.location)

    useEffect(() => {
        if (typeof window !== 'object') {
            return
        }

        if (!cityName && deliveyCityDataLoaded) {
            const domainKeys = Object.keys(domains);
            if (domainKeys.length === 0) {
                dispatch(fetchDomains());
                return;
            }
            getCity(domains);
        }
    }, [cityName, domains, deliveyCityDataLoaded])

    const region = useMemo(() => {
        return deliveyCityData?.region_with_type?.includes(cityName) ? '' : deliveyCityData.region_with_type;
    }, [deliveyCityData])

    const getCity = (domains) => {
        if (window.location.hostname === 'admintest.farkop.ru') {
            dispatch(setLocation('Москва'));
            return
        }
        let cookieCityName = Cookies.get('cityName');
        const isRobot = window.navigator?.userAgent?.includes('yandex') || window.navigator?.userAgent?.includes('google');
        if (!cookieCityName && !isRobot) {
            detectCity();
        }
        if (window.location.hostname === 'msk.farkop.ru' || window.location.hostname === 'msk.farkop-msk.ru') {
            if (cookieCityName) {
                if (deliveyCityData.region === 'Московская' || deliveyCityData.region === 'Москва') {
                    dispatch(setLocation(cookieCityName))
                } else {
                    dispatch(setLocation('Москва'));
                    dispatch(getDeliveryCityData({}, true))
                }
            } else {
                dispatch(setLocation('Москва'));
                dispatch(getDeliveryCityData({}, true))
            }
            return
        }
        if (window.location.hostname === 'spb.farkop.ru' || window.location.hostname === 'spb.farkop-msk.ru') {
            if (cookieCityName) {
                if (deliveyCityData.region === 'Санкт-Петербург' || deliveyCityData.region === 'Ленинградская') {
                    dispatch(setLocation(cookieCityName))
                } else {
                    dispatch(setLocation('Санкт-Петербург'));
                    dispatch(getDeliveryCityData({}, true))
                }
            } else {
                dispatch(setLocation('Санкт-Петербург'));
                dispatch(getDeliveryCityData({}, true))
            }
            return
        }
        const subDomain = window.location.hostname.split('.')[0]
        if (subDomain !== 'farkop' && subDomain !== 'farkop-msk' && domains[subDomain]) {
            if (subDomain === 'troick')  {
                dispatch(getDeliveryCityData({
                    "region_with_type": "Челябинская обл",
                    "region_type_full": "область",
                    "region": "Челябинская"
                }, true))
            }
            if (subDomain === 'chehov')  {
                dispatch(getDeliveryCityData({
                    "region_with_type": "Сахалинская обл",
                    "region_type_full": "область",
                    "region": "Сахалинская"
                }, true))
            }
            if (subDomain === 'klin')  {
                dispatch(getDeliveryCityData({
                    "region_with_type": "Нижнегородская обл",
                    "region_type_full": "область",
                    "region": "Нижнегородская"
                }, true))
            }
            dispatch(setLocation(domains[subDomain]['NAME']))
        } else if (subDomain === 'farkop' || subDomain === 'farkop-msk' || window.location.hostname === 'localhost') {
            if (cookieCityName) {
                //setLocationLogic(cookieCityName, {}, domains);
                dispatch(setLocation(cookieCityName));
            } else {
                setDoSetDetectedCity(true);
                dispatch(setLocation('Россия'));
                dispatch(getDeliveryCityData({}, true))
            }
        } else {
            window.location.href = (window.location.hostname.includes('farkop-msk.ru') ? 'https://farkop-msk.ru' : 'https://farkop.ru') + window.location.pathname + window.location.search;
        }
    }

    const detectCity = async () => {
        const detectedCity = await Api.dadata.detectCity();
        if (cityName !== detectedCity?.['city']) {
            setDetectedCity(detectedCity)
        }
    }

    /*useEffect(() => {
        if (detectedCity && doSetDetectedCity) {
            setDoSetDetectedCity(false);
            dispatch(setLocation(detectedCity.city));
            dispatch(getDeliveryCityData(detectedCity, true))
        }
    }, [detectedCity, doSetDetectedCity]);*/

    useEffect(() => {
        const body = document.querySelector("body");
        if (!detectedCity) {
            body.classList.remove("popup");
        } else {
            body.classList.add("popup");
        }
    }, [detectedCity]);

    const phone = useMemo(() => {
        setTimeout(() => {
            if (typeof window === 'object') {
                window?.ct_replace_495 && window?.ct_replace_495()
                window?.ct_replace_812 && window?.ct_replace_812()
                window?.ct_replace_800 && window?.ct_replace_800()
            }
        }, 500);
        return contactsData?.PHONE
    }, [contactsData])

    const deliveryInfo = useMemo(() => {
        if (!contactsData) {
            return ''
        }
        if (contactsData['NAME'] === 'Центральный') {
            if (edostMinDays > 0) {
                return `Доставка от ${declOfNum((edostMinDays), ['дня', 'дней', 'дней'])}`
            }
            return ''
        }
        return declOfNum(contactsData.SHOPS?.length, ['магазин', 'магазина', 'магазинов'])
    }, [contactsData, edostMinDays])


    const router = useRouter();
    const isCheckoutPage = useMemo(() => {
        if (router.asPath.indexOf('/personal/cart/') > -1) {
            return true
        }
        return false
    }, [router])

    const [pageScroll, setPageScroll] = useState(0)

    useEffect(() => {
        if (!isShowMobileMenu) {
            window.scrollTo(0, pageScroll);
        }
    }, [isShowMobileMenu]);

    const setCity = async () => {
        await dispatch(getDeliveryCityData(detectedCity, true))
        await dispatch(setLocation(detectedCity.city));

        setLocationLogic(detectedCity.city, detectedCity, domains);
        setDetectedCity('')
    }

    return (
        <header>
            {detectedCity?.city && (
                <noindex>
                    <div className={styles.Wrapper} onClick={() => {
                        setDetectedCity('')
                    }}>
                        <div onClick={e => e.stopPropagation()} className={styles.DetectedPopup}>
                            <div className={styles.DetectedCity}>Ваш город {detectedCity.city}?</div>
                            <div className={styles.DetectedButtons}>
                                <Button onClick={setCity} label={'Да, верно'}/>
                                <Button
                                    onClick={() => {
                                        dispatch(showPopup(popupTypes.locationNew))
                                        setDetectedCity('')
                                    }}
                                    type={'outline'}
                                    label={'Выбрать другой'}
                                />
                            </div>
                        </div>
                    </div>
                </noindex>
            )}
            <div className={styles.HeaderWpapper}>
                <div className={styles.Header}>
                    <div className={clsx(styles.Top, isScrolled && styles.TopScrolled, isShowMobileMenu && styles.TopMobiled)}>
                        <div className='container'>
                            <div className={styles.TopLeft}>
                                <div className={styles.Location} onClick={() => dispatch(showPopup(popupTypes.locationNew))}>
                                    {cityName && (
                                        <>
                                            <div className={styles.City}>
                                                {cityName}
                                            </div>
                                            {false && region && (<div className={styles.Region}>
                                                {`, ${region}`}
                                            </div>)}
                                            <span style={{color:'green'}}>{deliveryInfo && `(${deliveryInfo})`}</span>
                                            <Icon id='down' width={10} height={5}/>
                                        </>
                                    )}
                                    {!cityName && <Loader width={25}/>}
                                </div>
                                <div className={styles.Socs}>
                                    <Tg/>
                                    <Wp/>
                                </div>
                                <div className={styles.Phone}>
                                    <a href={`tel:${phone}`} className={contactsData.CALL_TOUCH_CODE} onClick={() => {
                                        if (window.location.href.includes('vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_moskve')) {
                                            if (typeof ym !== "undefined"){
                                                // @ts-ignore
                                                ym(20518312,'reachGoal','mskRegPhoneClick')
                                            }
                                        } else if (window.location.href.includes('vnesenie-izmeneniy-v-konstruktsiyu-ts-posle-ustanovki-farkopa_v_sankt-peterburge')) {
                                            if (typeof ym !== "undefined"){
                                                // @ts-ignore
                                                ym(20518312,'reachGoal','spbRegPhoneClick')
                                            }
                                        }

                                    }}>{phone}</a>
                                </div>
                            </div>

                            <Menu cityName={cityName}/>
                        </div>
                    </div>
                    <div className={styles.BodyWrapper}>
                        <div className={clsx(styles.Body, isScrolled && styles.BodyFixed, isShowMobileMenu && styles.BodyMobileMenu)}>
                            <div className='container'>
                                <div className={clsx(styles.Icons, styles.IconsMobile)}>
                                    <div
                                        className={clsx(styles.Menu, isShowMobileMenu && styles.MenuActive)}
                                        onClick={() => {
                                            if (!isShowMobileMenu) {
                                                setPageScroll(window.scrollY)
                                                window.scrollTo(0, 0);
                                            }

                                            dispatch(setShowMobileMenu(!isShowMobileMenu))
                                        }}
                                    >
                                        <div>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                    <Button
                                        type={'none'}
                                        onClick={() => { setSearchActive(i => i + 1) }}
                                        before={
                                            <Icon id='search' stroke={'#9294A9'} strokeWidth={2} size={24}/>
                                        }
                                        customStyles={clsx(styles.ButtonMobile, isShowMobileMenu && styles.ButtonMobileActive)}
                                    />
                                </div>
                                <Link href="/">
                                    <a className={styles.Logo}>
                                        <img src="/logo2.svg"/>
                                    </a>
                                </Link>
                                <Catalog isScrolled={isScrolled}/>
                                <div className={clsx(styles.Icons, isShowMobileMenu && styles.IconsMenuActive, isScrolled && styles.IconsScrolled)}>
                                    <Search
                                        activate={searchActivate}
                                        isScrolled={isScrolled}
                                    />
                                    <User user={user}/>
                                    {!isCheckoutPage && <Cart basket={basket}/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
})